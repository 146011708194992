import React from 'react';
import './testimonials.css';
import testimonialData from './TestimonialData';

// import Swiper core and required modules
import { Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Testimonials = () => {
  return (
    <section className="testimonials container section" id='testimonials'>
      <h2 className="section__title">Clients & Reviews</h2>

      <Swiper className="testimonials__container grid"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={30}
      slidesPerView={1}
      grabCursor= {true}
      loop = {true}
      pagination={{ clickable: true }}
      >
          {testimonialData.map(({id, image, title, subtitle, comment}) => {
            return(
              <SwiperSlide className="testimonial__item">
                <div className="thumb" key={id}>
                  <img src={image} alt="" />
                </div>
                <h3 className="testimonials__title">{title}</h3>
                <span className="subtitle">{subtitle}</span>
                <div className="comment">{comment}</div>
              </SwiperSlide>
            )
          })}
      </Swiper>
    </section>
  )
}

export default Testimonials